import React                   from 'react';
import { graphql }             from 'gatsby';
import Img                     from 'gatsby-image';
import { Container, Row, Col } from 'react-grid-system';
import styled                  from '@emotion/styled';

import Heading     from '@interness/web-core/src/components/text/Heading/Heading';
import Wrapper     from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import HeaderImage from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import Spacer      from '@interness/web-core/src/components/structure/Spacer/Spacer';
import CTASection  from '@interness/web-core/src/components/modules/CTASection/CTASection';

const List = styled.ul`
  margin-left: 0;
  li {
    margin: 0;
  }
`;

const ServicePage = (props) => {
  return (
    <>
      <HeaderImage>
        <Img fluid={props.data.headerImage.childImageSharp.fluid}/>
      </HeaderImage>
      <Wrapper>
        <Heading>Werkstatt</Heading>
        <h3>Gold- und Platinschmiede</h3>
        <p>Wir fertigen individuell für Sie Silber-, Gold- und Platinschmuck mit und ohne Edelsteine an.Im Gespräch mit Ihnen entwickeln wir ein individuelles Design ganz nach Ihren Vorstellungen. Dabei sind Ihrer und unserer Phantasie keine Grenzen gesetzt. Wir nutzen dabei alle erdenklichen Edelsteine und Edelmetalle.</p>
        <p>Gerne gravieren wir Ihr Schmuckstück nach Ihren individuellen Vorstellungen und Wünschen.</p>

        <h3>Reparaturen</h3>
        <p>Wir reparieren Ihre Schmuckstücke, egal ob zerissene Kette oder loser Stein an einem antiken Schmuckstück. Dabei kommt es nicht darauf an, ob das Schmuckstück aus Gold, Silber oder Platin ist.</p>
        <p>Mit unserem Laser-Schweißgerät können wir auch hitzeempfindliche Teile schonend und präzise reparieren.</p>

        <h3>Restaurierungen</h3>
        <p>Gern restaurieren wir Ihren antiken Schmuck. Wir säubern die Stücke, polieren sie auf und ersetzen fehlende Steine. Gebrochene Stellen reparieren wir mit unserem Laser-Schweißgerät ohne das empfindliche Schmuckstück zu beschädigen.</p>
        <Spacer />
        <Heading>Service</Heading>
        <Container fluid>
          <Row>
            <Col md={6}>
              <h3>Armbanduhren</h3>
              <List>
                <li>mechanische Uhren / Handaufzug</li>
                <li>Quarzarmbanduhren</li>
                <li>Automatikuhren</li>
                <li>Batteriewechsel</li>
                <li>Komplette Revisionen</li>
                <li>Glasersatz</li>
                <li>Leder-, Metall-, Kunststoffuhrarmbänder</li>
                <li>Bandanpassung</li>
                <li>Wasserdichtigkeitstest bis 10 Bar</li>
              </List>
            </Col>
            <Col md={6}>
              <h3>Großuhren</h3>
              <List>
                <li>Heim- und Tischuhren, Jahresuhren</li>
                <li>Standuhren</li>
                <li>Regulatoren</li>
              </List>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h3>Goldschmiedereparaturen</h3>
              <List>
                <li>Goldschmiedereparaturen aller Art</li>
                <li>Kette und Ösen zulöten, Verschlüsse ersetzen</li>
                <li>Ringgrößen ändern</li>
                <li>Perlenketten neu fassen oder knoten</li>
                <li>Trauringumarbeitung</li>
                <li>Neuanfertigungen</li>
                <li>Schmuck reinigen und aufarbeiten</li>
                <li>Gravuren in Ringe, Anhänger, Uhren, usw.</li>
                <li>Laserschweißen</li>
              </List>
            </Col>
            <Col md={6}>
              <h3>Altgoldankauf</h3>
              <List>
                <li>Wir kaufen Altgold aus vielen, unterschiedlichen Bereichen an</li>
                <li>Zahngold, Goldnuggets, Altgold aus Schmuckstücken</li>
                <li>Wir prüfen fachkundig und unkompliziert und vergüten nach aktuellen Edelmetallkursen</li>
                <li>Altgoldankauf ist Vertrauenssache</li>
              </List>
            </Col>
          </Row>
        </Container>
        <Spacer />
      </Wrapper>
      <CTASection/>
    </>
  )
};

export default ServicePage;

export const query = graphql`
  query {
    headerImage: file(relativePath: {eq: "index/werkstatt.jpg"}) {
      name
      childImageSharp {
        fluid(maxWidth: 1980, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;